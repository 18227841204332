<template>
  <div>
    <div id="pageEditMessage" class="mainContents">
      <UserNavSp :user="u" :page="'message'" />

      <UserTitle :user="u" />

      <UserNav :user="u" :page="'message'" />

      <div class="editMessageArea">
        <div class="inner">
          <h2>メッセージ一覧</h2>
          <table>
            <tr>
              <th class="date">
                日付
              </th>
              <th class="title">
                メッセージ
              </th>
              <th class="name">
                投稿者
              </th>
              <th class="delete">
                削除
              </th>
              <th class="block">
                ブロック
              </th>
            </tr>
            <MessageCard
              v-for="message in user.messages"
              :key="message.node.id"
              :message="message.node"
              @show-modal="show"
            />
          </table>

          <div class="buttonArea">
            <p>
              <button
                v-if="user.hasNextPage"
                type="submit"
                @click="getMoreMessages($event)"
              >
                もっと見る
              </button>
            </p>
            <!-- /.buttonArea -->
          </div>
        </div>
        <!-- /.editMessageArea -->
      </div>

      <!-- /.mainContents -->
    </div>

    <modal name="delete-message" :width="700" :height="263">
      <div id="modalDelete">
        <div class="modalInner">
          <p class="caution">
            メッセージを削除します。<br>
            完全に削除されますので、確認の上、削除をおこなってください。
          </p>
          <div class="buttonArea">
            <div class="inner">
              <p class="cancel">
                <a @click="hide">キャンセル</a>
              </p>
              <p class="submit">
                <button @click="deleteMessage">
                  削除する
                </button>
              </p>
            </div>
          </div>
          <p class="close">
            <a @click="hide" />
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import UserTitle from '@/components/UserTitle.vue'
import UserNav from '@/components/UserNav.vue'
import UserNavSp from '@/components/UserNavSp.vue'
import MessageCard from '@/components/MessageCard.vue'
import store from '@/store/index'
import { mapState } from 'vuex'

function getPageMessages (routeTo, next) {
  store
    .dispatch('user/fetchMessages', {
      first: 20,
      user: routeTo.params.id
    })
    .then(() => {
      next()
    })
}

export default {
  components: {
    MessageCard,
    UserTitle,
    UserNav,
    UserNavSp
  },
  props: {
    u: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      messages: [],
      deleteMessageId: null
    }
  },
  beforeRouteEnter (routeTo, routeFrom, next) {
    getPageMessages(routeTo, next)
  },
  computed: {
    ...mapState(['user'])
  },
  created: function () {
    store.dispatch('user/fetchBlockList')
  },
  methods: {
    show (id) {
      this.deleteMessageId = id
      this.$modal.show('delete-message')
    },
    hide () {
      this.$modal.hide('delete-message')
    },
    deleteMessage () {
      store
        .dispatch('user/deleteMessage', { id: this.deleteMessageId })
        .then(() => {
          this.hide()
        })
    },
    getMoreMessages (event) {
      event.preventDefault()
      const args = {
        first: 20,
        after: this.user.endCursor,
        add: true
      }
      store.dispatch('user/fetchMessages', args)
    }
  }
}
</script>

<style></style>
