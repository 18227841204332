<template>
  <tr>
    <td class="date">
      {{ formatDate(message.created) }}
      <span class="time">{{ formatTime(message.created) }}</span>
    </td>
    <td class="title">
      <router-link
        class="message-link"
        :to="{ name: 'message-show', params: { id: message.id } }"
      >
        {{ message.header }}
      </router-link>
    </td>
    <td class="name">
      <router-link
        :to="{ name: 'user-show', params: { id: message.from_user.id } }"
      >
        {{ message.from_user.full_name }}
      </router-link>
    </td>
    <td class="delete">
      <a @click="show">削除</a>
    </td>
    <td class="block">
      <BaseBlockButton :prop-user="message.from_user" />
    </td>
  </tr>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import { commonDate } from '@/helpers/date-format.js'

export default {
  name: 'MessageCard',
  props: {
    message: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...authComputed
  },
  methods: {
    show () {
      this.$emit('show-modal', this.message.id)
    },
    formatDate (date) {
      return commonDate(date, 'YYYY.MM.DD')
    },
    formatTime (date) {
      return commonDate(date, 'hh:mm')
    }
  }
}
</script>

<style></style>
